import React from 'react';
import "./style/button.scss"
function Button (props) {
    return (
        <div className="button">
            <a className="button__link" href={props.link} target={props.isTarget ? "_blank" : ""} rel="noreferrer" >
                {props.text}
            </a>
        </div>
    );
}

export default Button;
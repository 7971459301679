import React from "react"

function OfferIconServices(props) {
  return (
    <div className="offerIconServices">
      {props.children}
    </div>
  )
}

export default OfferIconServices
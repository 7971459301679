import React from "react"
import SmallTitle from "./smallTitle"
import BigTitle from "./BigTitle"
import "./style/servicesOffer.scss"

function ServicesOffer(props) {
  return (
    <section className="servicesOffer">
      <SmallTitle title={props.smallTitle} />
      <BigTitle title={props.title}/>
      <div className="servicesOffer__inner">
        {props.children}
      </div>
    </section>
  )
}

export default ServicesOffer
import React from "react"
import "./style/offerIcon.scss"

function OfferIcon(props) {
  return (
    <div className="offerIcon">
      {props.children}
      <h3 className="offerIcon__title">{props.title}</h3>
      {props.desc && <p className="blank">{props.desc}</p>}
    </div>
  )
}

export default OfferIcon
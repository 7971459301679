import React from "react"
import "./style/headerContentOffer.scss"

function HeaderContentOffer(props) {
  return (
    <header className="headerOffer paddingInner">
      <h1 className="headerOffer__title">
        {props.title}
      </h1>
      <h2>
        {props.subTitle ? <h2>{props.subTitle}</h2> : ""}
      </h2>
      <p className="blank">
        {props.desc}
      </p>
    </header>
  )
}

export default HeaderContentOffer
import React from 'react';

function OfferIconInner({title, desc}) {
    return (
        <div className="offerIcon__inner">
            <h3 className="offerIcon__title">{title}</h3>
            <p className="blank">{desc}</p>
        </div>
    );
}

export default OfferIconInner;
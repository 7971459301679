import React from "react"
import SmallTitle from "./smallTitle"
import BigTitle from "./BigTitle"
import { AiFillCheckCircle } from "react-icons/ai";
import "./style/benefits.scss"

function Benefits({title, smallTitle, benefitsArr, desc}) {
  return (
    <section className="benefits">
      <SmallTitle title={smallTitle}/>
      <BigTitle title={title}/>
      {desc && <p className="blank">{desc}</p>}
      <div className="benefits__inner">
        <ul>
            {benefitsArr.map( (item, index) => {
                return (
                    <li key={index}>
                        <AiFillCheckCircle/> <span>{item}</span>
                    </li>
                )
            })}
        </ul>
      </div>
    </section>
  )
}

export default Benefits
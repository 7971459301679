import React, {Component} from "react"
import Layout from "../components/layout"
import HeaderContentOffer from "../components/HeaderContentOffer"
import BackgroundHero from "../components/BackgroundHero"
import HowTo from "../components/howTo"
import ServicesOffer from "../components/ServicesOffer"
import PriceList from "../components/PriceList"
import Benefits from "../components/Benefits"
import OfferIcon from "../components/OfferIcon";
import SchemeIcon from "../assets/svgIcon/schemat.svg";
import CartIcon from "../assets/svgIcon/karta.svg";
import CodeIcon from "../assets/svgIcon/code.svg";
import CodeWebIcon from "../assets/svgIcon/codeWeb.svg";
import RaquietIcon from "../assets/svgIcon/rakieta.svg";
import OfferIconServices from "../components/OfferIconServices";
import OfferIconInner from "../components/OfferIconInner";
import SeoIcon from "../assets/svgIcon/seo.svg";
import LockIcon from "../assets/svgIcon/klodka.svg";
import ElasticIcon from "../assets/svgIcon/elastic.svg";
import ComputerChart from "../assets/svgIcon/computerChart.svg";
import WebsitesIcon from "../assets/svgIcon/websites.svg";
import DeliveryIcon from "../assets/svgIcon/delivery.svg";
import TruckIcon from "../assets/svgIcon/truck.svg";
import MultistoreIcon from "../assets/svgIcon/multistore.svg";
import SEO from "../components/seo"
import PriceListItem from "../components/PriceListItem"

class Shops extends Component {
    render() {
        return (
            <Layout footerDesc="Nie tylko tworzymy sklepy internetowe, ale współpracujemy z Tobą, aby pomóc Ci zrozumieć, gdzie
            Twoja firma ma możliwości rozwoju i pomóc Ci osiągnąć Twoje cele.
            CodersBros z siedzibą w Kielcach, Polska jest dla Ciebie pierwszym sprzymierzeńcem w biznesie. Twoje cele są naszymi celami.">
                <SEO title="Sklepy internetowe Kielce|Sklepy internetowe Nopcommerce "
                     description="Uruchom swój sklep internetowy z nami na platformie nopcommerce.
                     Nasze sklepy internetowe są niedrogie i profesjonalne. Robimy wszystko dla Ciebie,
                     abyś mógł skoncentrować się na swoim biznesie."
                />
                <HeaderContentOffer title="Sklepy Internetowe"
                                    desc="Uzyskaj idealne połączenie pożądanych funkcji i własny solidny
                                          sklep internetowy oparty na nopCommerce, aby zapewnić
                                          niezapomniane wrażenia dla użytkownika.
                                          Dzięki Coderbros  jednej z najlepszych agencji
                                          zajmującej się projektowaniem i budową sklepów internetowych w
                                          Kielcach oraz oprogramowaniu nopCommerce już dziś możesz
                                          zrealizować swój cel."/>
                <BackgroundHero img="eCommerce"/>
                <HowTo title="Co sprawia że profesjonalny sklep internetowy odnosi sukces?"
                smallTitle="jak działamy?"
                       desc="Specjalizujemy się w profesjonalnym projektowaniu oraz rozwijaniu
                              sklepów internetowych opartych o slinik nopCommerce. Nasze
                              projekty tworzymy z pasją i zawsze skupiamy się na jak najlepszym
                              wykonaniu - a mówiąc prościej tworzymy sklepy w taki sposób aby
                              przynosiły one jak największe profity naszym klientom."
                >
                    <div className="howTo__inner paddingInside">
                        <OfferIcon title="1. Planowanie" desc="Tym, co sprawia, że ​​każdy sklep WWW jest wyjątkowy, jest jego oryginalność i nieprzeciętny
desing. Przyciągnij uwagę klienta niestandardowym motywem internetowym
zaprojektowanym specjalnie dla Twojego sklepu eCommerce. Pakiety projektowe są
dostępne dla wszystkich rodzajów firm i zakresu budżetu">
                            <SchemeIcon />
                        </OfferIcon>
                        <OfferIcon title="2. Projekt" desc="Pozwól nam stworzyć niestandardowe rozwiązanie dla Twojego sklepu
internetowego. Nasi wykwalifikowani i doświadczeni programiści specjalizują się w
niestandardowej integracji API, zaawansowanych funkcjach sklepu internetowego i
tworzeniu aplikacji mobilnych dostarczą obsługę na najwyższym poziomie. Nowe

funkcje i aktualizacje Sklepu e-commerce spowodują zwiększenie sprzedaży i
zmniejszenie ilości zwrotów">
                            <CartIcon />
                        </OfferIcon>
                        <OfferIcon title="3. Optymalizacja" desc="Dotrzyj do klientów i potencjalnych klientów, którzy szukają twoich produktów i
usług. Popraw swoją tożsamość marki lub promuj produkty eCommerce poprzez ,
optymalizację dla wyszukiwarek i marketing w mediach społecznościowych.">
                            <CodeWebIcon />
                        </OfferIcon>
                        <OfferIcon title="4. Konserwacja" desc="Regularna konserwacja witryny sklepu internetowego ma najwyższe znaczenie dla ciągłości
biznesowej, aby zapobiec możliwej utracie przychodów i uniknąć krytycznych błędów. Już
teraz jesteś zajęty opieką nad swoim biznesem w pełnym wymiarze godzin, zostaw nam
prace konserwacyjne i wsparcie i zrelaksuj się!">
                            <RaquietIcon />
                        </OfferIcon>
                    </div>
                </HowTo>
                <ServicesOffer title="Dlaczego nopCommerce"
                               smallTitle="co oferujemy?">
                    <OfferIconServices>
                        <WebsitesIcon/>
                        <OfferIconInner
                            title="nopCommerce"
                            desc="Jest to oparty na technologiach Microsoftu, jeden z najpopularniejszych sliników dla sklepów internetowych. Cechuje go bezpieczeństwo, ponadprzeciętna wydajność oraz wysoka intuicyjność, pozwalająca Tobie na samodzielną obsługę. Zarządzanie magazynem, wielojęzyczność, wielowalutowość to tylko niektóre z dostępnych funckji nopCommerce więcej o tym silniku przeczytasz tutaj"
                        />
                    </OfferIconServices>

                    <OfferIconServices>
                        <SeoIcon/>
                        <OfferIconInner
                            title="Wysoka sprzedaż"
                            desc="Intuicyjność, funkcjonalność, przyjemny dla oka design to tylko parę czynników, które sprawiają, że klient chce zrobić zakupy na Twoim sklepie internetowym. Nasza firma dokłada wszelkich starań, aby wykonanie jakie Tobie oferujemy przekładało się na wysokie zaintersowanie produktem."
                        />
                    </OfferIconServices>
                    <OfferIconServices>
                        <LockIcon/>
                        <OfferIconInner
                            title="Bezpieczeństwo"
                            desc="Payment Card Industry Data Security Standard (PCI DSS) to
                                międzynardowy zbiór standardów i wymagań ochrony informacji w
                                sektorze finansowym. Silnik nopCommerce spełnia je wszystkie,
                                zintegrowane metody płatności i bramki do bezpiecznego
                                przesyłania danych kart kredytowych sprawia, że możesz czuć się
                                bezpiecznie."
                        />
                    </OfferIconServices>
                    <OfferIconServices>
                        <ElasticIcon/>
                        <OfferIconInner
                            title="Elastyczność"
                            desc="Silnik nopCommerce jest w pełni skalowany, oznacza to, że będzie
                                świetnym rozwiązaniem zarówno dla sklepów małych jak i dużych."
                        />
                    </OfferIconServices>
                    <OfferIconServices>
                        <MultistoreIcon/>
                        <OfferIconInner
                            title="Multistore"
                            desc="Uzyskaj obsługę kilku sklepów z 1 instalacji nopCommerce. Możesz udostępniać swój
                                produkt biznesowy w różnych sklepach internetowych. Ponadto Multistore umożliwia
                                także posiadanie określonego sklepu firmowego, partnerskiego lub tymczasowego,
                                wszystko z jednego miejsca."
                        />
                    </OfferIconServices>
                    <OfferIconServices>
                        <DeliveryIcon/>
                        <OfferIconInner
                            title="Wielu dostawców"
                            desc="Dzięki usłudze wielu dostawców lub usłudze wysyłkowej możesz mieć sklep bez
                                wystarczającej ilości miejsca do przechowywania swoich produktów. Teraz znajdź
                                produkty wielu dostawców we wspólnym katalogu produktów. Każdy sprzedawca
                                otrzymuje dostęp do panelu administracyjnego, aby dbać o swoje produkty, raporty i
                                inne, również bez ingerencji innego dostawcy."
                        />
                    </OfferIconServices>
                    <OfferIconServices>
                        <WebsitesIcon/>
                        <OfferIconInner
                            title="Elastyczna konfiguracja produktu"
                            desc="Dzięki NopCommerce możesz wybrać elastyczną konfigurację produktu i aktualizować
                                swój sklep internetowy, dodając zmienne produktu, sprzedaż krzyżową, dostosowując
                                atrybuty, dodając konkretną alternatywę kasy lub posiadając wiele zdjęć
                                produktu. Możesz także określić zamówienie zwrotne, zamówienie wstępne, powiązanie
                                produktu z produktem i wiele więcej w celu zwiększenia zapasów produktów."
                        />
                    </OfferIconServices>
                    <OfferIconServices>
                        <ComputerChart/>
                        <OfferIconInner
                            title="Pozycjonowanie"
                            desc="Łatwość optymalizacji sklepu nopCommerce pod względem wyszukiwarek to kolejna
                                wspaniała ceche tego silnika. Zwiększ sprzedaż produktów, oferując rabaty, kupony
                                promocyjne, rabaty ilościowe, punkty lojalnościowe itp. Dostarczaj również oferty
                                dostosowane do potrzeb, biorąc pod uwagę historię zakupów klientów. Dzięki
                                Nopcommerce uzyskasz przyjazny adres URL, mapy witryn i metatagi na produkt, dzięki
                                czemu uzyskasz zoptymalizowane wsparcie SEO"
                        />
                    </OfferIconServices>
                    <OfferIconServices>
                        <TruckIcon/>
                        <OfferIconInner
                            title="Przesyłki"
                            desc="Skonfiguruj własnego dostawcę, ponieważ Nopcommerce zawiera wstępnie
                                skonfigurowane i elastyczne agenty wysyłkowe, takie jak FedEx i UPS. Znajdź koszt
                                wysyłki podczas zamawiania i śledzenia kodów oraz przesyłanych informacji." />
                    </OfferIconServices>
                </ServicesOffer>
                <PriceList title="Ile kosztuje sklep internetowy?"
                           smallTitle="ile to kosztuje?">
                  <PriceListItem
                    title="Podstawowy"
                    price="od 2500zł"
                    services={[
                      "Gotowy projekt graficzny",
                      "Platforma nopcommerce",
                      "Responsywny desing kompatybilny z wieloma urządzeniami",
                      "Projekty banerów",
                      "Przesuwny baner",
                      "Możliwość importu do 10 produktów",
                      "Nieograniczona ilość zdjęć",
                      "System zarządzania treścią CMS",
                      "Formularz kontaktowy",
                      "Pasek wyszukiwania produktów",
                      "Porównywarka produktów",
                      "Integracja koszyka",
                      "Opcja kasy dla gości",
                      "Moduł rejestracji do newslettera",
                      "Integracja Payment Card Industry",
                      "Widgety mediów społecznościowych na żywo",
                      "Możliwość instalacji darmowych pluginów",
                      "Dedykowany kierownik projektu",
                      "Satysfkacja gwarantowana",
                    ]}
                  />
                  <PriceListItem
                    title="Standardowy"
                    price="od 5000zł"
                    services={[
                      "Dedykowany projekt graficzny",
                        "Platforma nopcommerce",
                        "Responsywny desing kompatybilny z wieloma urządzeniami",
                        "Projekty banerów",
                        "Przesuwny baner",
                        "Możliwość importu do 30 produktów",
                        "Nieograniczona ilość zdjęć",
                        "System zarządzania treścią CMS",
                        "Formularz kontaktowy",
                        "Pasek wyszukiwania produktów",
                        "Porównywarka produktów",
                        "Integracja koszyka",
                        "Opcja kasy dla gości",
                        "Moduł rejestracji do newslettera",
                        "Integracja Payment Card Industry",
                        "Widgety mediów społecznościowych na żywo",
                        "Integracja czatu",
                      "Opcje zarządzania zapasami",
                        "Możliwość instalacji darmowych pluginów",
                        "Dedykowany kierownik projektu",
                        "Satysfkacja gwarantowana",

                    ]}
                  />
                  <PriceListItem
                    title="Pro"
                    price="od 7500zł"
                    services={[
                        "Dedykowany projekt graficzny",
                      "Nieograniczone zmiany projektu",
                        "Platforma nopcommerce",
                        "Responsywny desing kompatybilny z wieloma urządzeniami",
                        "Projekty banerów",
                        "Przesuwny baner",
                        "Nieograniczony import produktów",
                        "Nieograniczona ilość zdjęć",
                      "Nieograniczone kategorie",
                        "System zarządzania treścią CMS",
                        "Formularz kontaktowy",
                        "Pasek wyszukiwania produktów",
                        "Porównywarka produktów",
                        "Integracja koszyka",
                        "Opcja kasy dla gości",
                        "Moduł rejestracji do newslettera",
                        "Integracja Payment Card Industry",
                        "Widgety mediów społecznościowych na żywo",
                        "Integracja czatu",
                        "Opcje zarządzania zapasami",
                        "Możliwość instalacji darmowych pluginów",
                        "Dedykowany kierownik projektu",
                        "Satysfkacja gwarantowana",
                    ]}
                  />
                </PriceList>
                <Benefits title="Korzyści dla Ciebie ze współpracy z CodersBros"
                          smallTitle="dlaczego my?"
                          benefitsArr={["Optymalizacja wydajności sklepu nopCommerce",
                              "Tworzenie aplikacji mobilnych",
                              "Niestandardowy rozwój",
                              "Tworzenie motywów (motywy mobilne i stacjonarne)",
                              "Migracja danych nopCommerce",
                              "Uaktualnienie nopCommerce Integracja z CDN"]}
                />

            </Layout>
        )
    }
}


export default Shops

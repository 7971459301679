import React from "react"
import SmallTitle from "./smallTitle"
import BigTitle from "./BigTitle"
import OfferIcon from "./OfferIcon"
import SchemeIcon from "../assets/svgIcon/schemat.svg"
import CartIcon from "../assets/svgIcon/karta.svg"
import RaquietIcon from "../assets/svgIcon/rakieta.svg"
import CodeIcon from "../assets/svgIcon/code.svg"
import "./style/howTo.scss"

function HowTo(props) {
  return (
    <section className="howTo">
      <SmallTitle title={props.smallTitle}/>
      <BigTitle title={props.title}/>
      <p className="howTo__text blank">
          {props.desc}
      </p>
        {props.children}
      {/*<p className="howTo__description blank">*/}
      {/*  Nasi eksperci ds. projektowania i tworzenia stron internetowych analizują Twój biznes i*/}
      {/*  opracowują plan wykonania projektu profesjonalnej strony WWW wraz z optymalną*/}
      {/*  funkcjonalnością.*/}
      {/*</p>*/}
    </section>
  )
}

export default HowTo
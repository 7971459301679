import React from "react"
import Button from "./Button"
import "./style/priceListItem.scss"

function PriceListItem({ title, price, services}) {
  const list = services.map( (service, index) => {
    return <li key={index}>{service}</li>
  })
  return (
    <div className="priceListItem">
      <div className="priceListItem__inner">
      <h4 className="priceListItem__title">{title}</h4>
      <p className="priceListItem__price">{price}</p>
      <ul className="priceListItem__list">
        {list}
      </ul>
      </div>
      <Button link="/#kontakt" text="Zamów"/>
    </div>
  )
}

export default PriceListItem
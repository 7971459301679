import React from "react"
import SmallTitle from "./smallTitle"
import BigTitle from "./BigTitle"
import PriceListItem from "./PriceListItem"
import "./style/priceList.scss"

function PriceList( {smallTitle, title, children}) {
  return (
    <section className="priceList">
      <SmallTitle title={smallTitle} />
      <BigTitle title={title} />
      <div className="priceList__inner">
        {children}
      </div>
    </section>
  )
}

export default PriceList